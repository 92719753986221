import 'html5shiv';

import 'fix-ie/src/es5-arrays';
import 'fix-ie/src/es5-methods';
import 'fix-ie/src/remove';
import 'fix-ie/src/token-list';
import 'fix-ie/src/version-flags';

import 'custom-event-polyfill';
import './Utility/Polyfill';
